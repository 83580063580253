import { CdkCopyToClipboard } from "@angular/cdk/clipboard";
import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Optional } from "@angular/core";
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from "@angular/material/bottom-sheet";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ContactDto, CreateNoteDto, NoteDto } from "@smallstack/axios-api-client";
import { DetailDialogData, NonEmptyStringPipe } from "@smallstack/common-components";
import { ContactCustomFieldStore, ContactsStore } from "@smallstack/crm-components";
import { DateComponent, I18nComponent } from "@smallstack/i18n-components";
import { FilterDataType } from "@smallstack/store-components";
import { CopyButtonComponent } from "@smallstack/text-components";
import { IconComponent } from "@smallstack/theme-components";
import { AvatarComponent, DisplayNamePipe } from "@smallstack/user-components";
import { ExtensionSlotComponent } from "@smallstack/widget-core";

@Component({
  selector: "smallstack-contact-detail-dialog",
  templateUrl: "./contact-detail-dialog.component.html",
  styleUrls: ["./contact-detail-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DisplayNamePipe,
    AvatarComponent,
    I18nComponent,
    IconComponent,
    NonEmptyStringPipe,
    DateComponent,
    CopyButtonComponent,
    CdkCopyToClipboard,
    AsyncPipe,
    ExtensionSlotComponent
  ]
})
export class ContactDetailDialogComponent {
  public FilterDataType = FilterDataType;
  public showContact = true;
  public showNotes = false;

  public contactNotes: NoteDto[] = [];
  public contact: ContactDto;
  public contactCustomFieldIds: any[] = [];

  public readonly saveNoteFn = async (contactId: string, createNote: CreateNoteDto): Promise<void> => {
    if (contactId !== undefined && createNote !== undefined) {
      await this.contactStore.createContactNote(contactId, createNote);
    }
  };

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) dialogData: DetailDialogData,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) contactBottomSheet: ContactDto,
    @Optional() private matDialogRef: MatDialogRef<ContactDetailDialogComponent>,
    @Optional() private matBottomSheetRef: MatBottomSheetRef,
    public matDialog: MatDialog,
    private contactStore: ContactsStore,
    public contactCustomFieldStore: ContactCustomFieldStore,
    private cdr: ChangeDetectorRef
  ) {
    void this.contactCustomFieldStore.preload();
    if (dialogData) this.contact = dialogData.model;
    else this.contact = contactBottomSheet;
    if (this.contact?.customFields instanceof Array)
      this.contactCustomFieldIds = Object.values(this.contact.customFields)?.map((customFieldValue) => {
        return customFieldValue.customFieldId;
      });
    void this.loadNotes();
  }

  public async loadNotes(): Promise<void> {
    this.contactNotes = (await this.contactStore.getNotes(this.contact?.id)).elements;
    this.cdr.markForCheck();
  }

  public close(): void {
    if (this.matDialogRef) this.matDialogRef.close();
    if (this.matBottomSheetRef) this.matBottomSheetRef.dismiss();
  }
}
